import { AnalyticsProvider, TrackingProvider } from '@sortlist-frontend/tracking';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { assertNonEmptyString, HttpBadRequest, HttpException } from '@sortlist-frontend/utils';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { Fragment, useRef } from 'react';

import { PageHead } from '_components/base/head';
import { Public404Page } from '_components/error/public-404-page';
import { LayoutPagesRouter } from '_components/layout/LayoutPagesRouter';
import { GenericPageSeo } from '_components/seo/generic-page-seo';
import { cacheConfig } from '_config/cache.config';
import { extractDomainRelevantInfoFromHeaders } from '_config/domain.config';
import { seoConfig } from '_config/next-seo.config';
import { AppProviders } from '_core/app-providers';
import { PublicAppContextProvider } from '_core/context/public-app-context';
import { isSerializedError, SerializedError, serializeError } from '_core/error/error-serializer';
import {
  cacheKey,
  getPublicApiRequestLink,
  PublicApiRepo,
  publicApiRepo,
  useGetPage,
} from '_core/repos/public-api.repo';
import { baseSsrPrefetchQueries } from '_core/utils/public-api';
import { getServerSideTranslations } from '_core/utils/ssr';
import { homeConfig } from '_features/home/home.config';
import HomePage from '_features/home/home.page';

const DOMAIN_CONFIG_ERROR_STATUSES = [400, 404, 410];

type Prop = {
  canonical: string;
  query: string;
  origin?: string;
  error: SerializedError | null;
  locale?: string;
  url?: string;
  dehydratedState?: DehydratedState;
};

export default function HomeRoute(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return (
    <AppProviders dehydratedState={props.dehydratedState}>
      <Route {...props} />
    </AppProviders>
  );
}

const Route = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { canonical, query, origin = '', error, locale = 'en', url = '' } = props;
  const scrollableCtn = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(homeConfig.i18nNamespaces);
  const { data: ssrData } = useGetPage(url);

  const { metadata, indexable, frontend_type } = ssrData?.data?.attributes || {};

  if (error && isSerializedError(error)) {
    const { status } = error;
    if (DOMAIN_CONFIG_ERROR_STATUSES.includes(status as number))
      return <Public404Page message={t('common:pageErrors.occured')} locale={locale} />;
  }

  const seoData = {
    title: metadata?.title_tag || '',
    description: metadata?.metadescription || '',
    nextSeoProps: {
      nofollow: !indexable,
      noindex: !indexable,
    },
    image: seoConfig.genericPageSeo.image,
    canonical,
  };

  PublicApiRepo.publicApiUrl = url;
  PublicApiRepo.publicApiNavBarUrl = url;

  if (ssrData) {
    return (
      <Fragment>
        <PageHead showAlternates={true} canonical={canonical} query={query} origin={origin} />
        <PublicAppContextProvider
          canonical={canonical}
          origin={origin}
          locale={locale}
          briefingOptions={{
            page: frontend_type || '',
          }}>
          <GenericPageSeo {...seoData} />
          <AnalyticsProvider>
            <TrackingProvider app="appPublic" category={ssrData?.data.attributes.frontend_type} name={'public'}>
              <LayoutPagesRouter scrollableCtn={scrollableCtn} showSubnav>
                <HomePage />
              </LayoutPagesRouter>
            </TrackingProvider>
          </AnalyticsProvider>
        </PublicAppContextProvider>
      </Fragment>
    );
  }
  return <Public404Page message={t('common:pageErrors.occured')} locale={locale} />;
};

export const getServerSideProps: GetServerSideProps<Prop> = async (context) => {
  let error: SerializedError | null = null;
  const { i18nNamespaces } = homeConfig;
  const queryClient = new QueryClient();

  let domainRelevantInfo;
  try {
    domainRelevantInfo = extractDomainRelevantInfoFromHeaders(context.req);
  } catch (e) {
    if (e instanceof HttpException) {
      // @TODO: use a more elegant way when upgrade to NextJS 10 https://nextjs.org/blog/next-10#redirect-and-notfound-support-for-getstaticprops--getserversideprops
      context.res.statusCode = e.status;
      error = serializeError(e);
    }

    return {
      props: {
        error,
        canonical: '',
        query: '',
        ...(await getServerSideTranslations('en', i18nNamespaces)),
      },
    };
  }

  const { canonical, origin, locale } = domainRelevantInfo;
  const queryParams = Object.entries(context.query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  assertNonEmptyString(origin, new HttpBadRequest({ message: 'Missing [origin] parameter' }));

  const url = getPublicApiRequestLink(origin, locale, context.resolvedUrl);

  try {
    await Promise.allSettled([
      queryClient.prefetchQuery({
        queryKey: [cacheKey.page(url)],
        queryFn: () => publicApiRepo.getPage({ page: url }),
      }),
      queryClient.prefetchQuery({
        queryKey: [cacheKey.agencies(url, 'organic')],
        queryFn: () =>
          publicApiRepo.getAgencies({
            page: url,
            section: 'organic',
            sort: 'position',
            number: '1',
            limit: 200,
            fieldsNeeded:
              'name,slug,logo,reviews_rating_total,reviews_count,tagline,description,visibility_plan,external_id',
          }),
      }),
      queryClient.prefetchQuery({ queryKey: [cacheKey.reviews], queryFn: () => publicApiRepo.getReviews() }),
      baseSsrPrefetchQueries(origin, locale, context.resolvedUrl, queryClient, true),
    ]);
  } catch (e: unknown) {
    if (e instanceof HttpException) {
      // Limitation: Typescript exceptions are not typed, so we rely on what is supposed to happen
      if (e.status !== 404) {
        throw e;
      }

      context.res.statusCode = 404;
      error = serializeError(e);
    }
  }

  if (!context.res.headersSent) {
    context.res.setHeader('Cache-Control', cacheConfig.pages.home?.httpCacheControl || '');
  }

  return {
    props: {
      canonical,
      query: queryParams,
      origin,
      locale,
      error,
      url,
      dehydratedState: dehydrate(queryClient),
      ...(await getServerSideTranslations(locale, i18nNamespaces)),
    },
  };
};
